<template>
    <div class="my-notice">
        <template v-if="noticeList.length > 0">
            <van-list
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="getNoticeFunc"
            >
                <div v-for="(item, index) in noticeList" :key="index" class="m-li flex" @click="goNoticeDetailFunc(item.id)">
                    <img :src="item.pic" class="pic">
                    <div class="center">
                        <p>{{ item.title }}</p>
                        <p class="time">{{ item.startTime }}</p>
                    </div>
                    <img src="@/assets/img/user/myNotice/right.png" class="right">
                </div>
            </van-list>
        </template>
        <template v-else>
            <img src="@/assets/img/user/myNotice/no-data.png" alt="" class="no-img">
            <p class="no-text">没有任何公告</p>
        </template>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>