import {
    getNotice
} from '@/utils/home.js';
export default {
    name: "MyNotice",
    data() {
        return {
            noticeList: [],
            page: 1,
            oldPage: 0,
            loading: false,
            finished: false,
        }
    },
    created() {
        this.getNoticeFunc();
    },
    methods: {
        // 获取公告列表
        getNoticeFunc() {
            if (this.oldPage == this.page) return false;
            this.oldPage = this.page;
            let data = {
                pageNo: this.page,
                pageSize: 10,
                type: 1
            }
            getNotice(data).then(res => {
                this.loading = false;
                if (res && res.code == "0") {
                    let arr = res.data;
                    arr.forEach(item => {
                        this.noticeList.push(item)
                    })

                    if (arr.length < 10) {
                        this.finished = true;
                    } else {
                        this.page++;
                    }
                } else {
                    this.finished = true;
                }

            })
        },
        // 跳转到公告详情
        goNoticeDetailFunc(aId) {
            this.$router.push({
                path: '/noticeContent',
                query: {
                    aId: aId
                }
            })
        }
    }
}